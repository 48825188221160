import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import AboutLeftNav from '../components/AboutLeftNav';
import AboutCenterContent from '../components/AboutContent';
import { MDContent } from '../components/Content';
import AboutBreadcrumbs from '../components/AboutBreadcrumbs';

const AboutPageTemplate = ({ page }) => {
  const [navCollapsed, setNavCollapsed] = useState(false);

  return (
    <>
      <section className="position-relative pt-13 mt-n12 documentation">
        <div className="container-fluid">
          <div className="row">
            <AboutBreadcrumbs toggleClick={() => setNavCollapsed(!navCollapsed)} page={page.page} />
            <AboutLeftNav collapsed={navCollapsed} />
            <AboutCenterContent content={page.html} contentComponent={MDContent} />
          </div>
        </div>
      </section>
    </>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  html: PropTypes.string,
};

const AboutPage = ({ data }) => {
  const { frontmatter, fields } = data.markdownRemark;

  const page = {
    html: data.markdownRemark.html,
    page: frontmatter.headline,
  };

  return (
    <Layout lang={{ slug: fields.slug, langKey: fields.langKey }} fluid={true} noFooter={true} noStats={true} whiteHeader={true}>
      <Helmet>
        <body className="bg-light" />
      </Helmet>
      <AboutPageTemplate page={page} />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default AboutPage;

export const pageQuery = graphql`
  query AboutPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
        langKey
      }
      frontmatter {
        headline
      }
    }
  }
`;
